import { getWeb3 } from "@/scripts/utils/onboard";
import getCookie from "../../utils/getCookie";
import { payETH, payERC20 } from "./payment";
import store from "@/store";
import Swal from "sweetalert2";

const errorInPayment = (err) => {
    Swal.fire({
        icon: "error",
        buttonsStyling: false,
        titleText: "Error making payment",
        html: `${err}`,
        confirmButtonColor: "#417dd8",
        confirmButtonText: "Back",
    });
};

const successfulPayment = () => {
    Swal.fire({
        icon: "success",
        buttonsStyling: false,
        titleText: "Payment successful",
        text: `
                 
                 `,
        confirmButtonColor: "#417dd8",
        confirmButtonText: "Continue",
    });
};

export function payWithETH(form) {
    return getWeb3()
        .then((web3) => {
            return payETH(store.state.offer, web3);
        })
        .then((txhash) => {
            return fetch(`/api/org/${store.state.active_org.id}/credits/${store.state.offer.payload.paymentID}/txhash`, {
                method: "POST",
                body: txhash,
                headers: {
                    "Content-Type": "text/plain",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
            });
        })
        .then((res) => {
            if (res.ok) {
                successfulPayment();
                form.reset();
            }
        })
        .catch((err) => {
            if (err === "User rejected") {
                errorInPayment("Didn't Select Wallet");
            } else {
                errorInPayment(err.message);
            }
        });
}

export function payWithERC20(form) {
    return getWeb3()
        .then((web3) => {
            return payERC20(store.state.offer, web3);
        })
        .then((txhash) => {
            return fetch(`/api/org/${store.state.active_org.id}/credits/${store.state.offer.payload.paymentID}/txhash`, {
                method: "POST",
                body: txhash,
                headers: {
                    "Content-Type": "text/plain",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
            });
        })
        .then((res) => {
            if (res.ok) {
                successfulPayment();
                form.reset();
            }
        })
        .catch((err) => {
            if (err === "User rejected") {
                errorInPayment("Didn't Select Wallet");
            } else {
                errorInPayment(err.message);
            }
        });
}
