<script setup>
import { defineEmits } from "vue";
import tokens from "@/db/pay_token.json";
import { ref } from "@vue/reactivity";
import Fade from "@/components/fade.vue";
import { useStore } from "vuex";
const emit = defineEmits(["submitOnSelect"]);
const store = useStore();
const showModal = ref(false);
const val = ref(0);
const toggleModal = () => {
    showModal.value = !showModal.value;
};

const closeModal = (e) => {
    if (!e.target.classList.contains("select_modal")) {
        showModal.value = !showModal.value;
    }
};

const selectToken = (token) => {
    val.value = token.id;
    store.state.token_for_purchase = token.name;
    if (store.state.offer) {
        emit("submitOnSelect");
    }
};
</script>

<template>
    <div @click="toggleModal" class="token_dropdown flex flex_r">
        <img width="30" :src="tokens[val].imgurl" alt="" />
        <span class="default_net">{{ tokens[val].name }}</span>
        <div class="toggleDropDown">
            <img src="@/assets/drop-down.svg" alt="" />
        </div>
    </div>

    <Fade>
        <div @click="closeModal" v-if="showModal" class="select_modal dropdown">
            <ul class="flex flex_col">
                <li class="flex" v-for="token in tokens" @click="selectToken(token)" :key="token.imgurl">
                    <img width="30" :src="token.imgurl" alt="" />
                    <p>{{ token.name }}</p>
                    <img v-if="token.id == val" src="@/assets/active.svg" alt="" />
                </li>
            </ul>
        </div>
    </Fade>
</template>

<style lang="scss" scoped>
.token_dropdown {
    width: 8rem;
    padding: 0.8rem 1rem;
    border-radius: 2rem;
    background: $fadegrey;
    cursor: pointer;
    @extend %noselect;
    img {
        @extend %nodrag;
    }
}
.select_modal {
    width: 15rem;
    position: absolute;
    top: 45%;
    backdrop-filter: brightness(10%);
    padding: 2rem 2rem;
    left: 45%;
    @include media("<=desktop") {
        left: 35%;
        top: 55%;
        padding: 1.5rem;
    }
    @include media("<=phone") {
        left: 15%;
        top: 60%;
        padding: 1.5rem;
    }
    ul {
        list-style-type: none;
        gap: 1rem;
        cursor: pointer;
        li {
            gap: 0.5rem;
        }
    }
}
</style>
