import { ref } from "vue";
import Web3 from "web3";
const networkId = ref(3);
const domainSchema = [
    { name: "name", type: "string" },
    { name: "version", type: "string" },
    { name: "chainId", type: "uint256" },
    { name: "verifyingContract", type: "address" },
];

const permitSchema = [
    { name: "holder", type: "address" },
    { name: "spender", type: "address" },
    { name: "nonce", type: "uint256" },
    { name: "expiry", type: "uint256" },
    { name: "allowed", type: "bool" },
];

const UNLIMITED_ALLOWANCE = "115792089237316195423570985008687907853269984665640564039457584007913129639935";

let approveABI = [
    {
        constant: false,
        inputs: [
            { name: "_spender", type: "address" },
            { name: "_value", type: "uint256" },
        ],
        name: "approve",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: true,
        inputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "address", name: "spender", type: "address" },
        ],
        name: "allowance",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "balanceOf",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
];

let paymentABI = [
    {
        constant: true,
        inputs: [],
        name: "target_token",
        outputs: [{ internalType: "contract ERC20", name: "", type: "address" }],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            { internalType: "bytes32", name: "paymentId", type: "bytes32" },
            { internalType: "uint256", name: "expiration", type: "uint256" },
            { internalType: "uint256", name: "tokAmt", type: "uint256" },
            { internalType: "uint256", name: "ethAmt", type: "uint256" },
            { internalType: "address", name: "depositAddress", type: "address" },
            { internalType: "bytes", name: "signature", type: "bytes" },
        ],
        name: "payERC20",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            { internalType: "bytes32", name: "paymentId", type: "bytes32" },
            { internalType: "uint256", name: "expiration", type: "uint256" },
            { internalType: "uint256", name: "tokAmt", type: "uint256" },
            { internalType: "uint256", name: "ethAmt", type: "uint256" },
            { internalType: "address", name: "depositAddress", type: "address" },
            { internalType: "bytes", name: "signature", type: "bytes" },
            { internalType: "bytes", name: "permitsignature", type: "bytes" },
        ],
        name: "payERC20Permit",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            { internalType: "bytes32", name: "paymentId", type: "bytes32" },
            { internalType: "uint256", name: "expiration", type: "uint256" },
            { internalType: "uint256", name: "tokAmt", type: "uint256" },
            { internalType: "address", name: "depositAddress", type: "address" },
            { internalType: "bytes", name: "signature", type: "bytes" },
        ],
        name: "payETH",
        outputs: [],
        payable: true,
        stateMutability: "payable",
        type: "function",
    },
];

export function payETH(offer, web3) {
    return new Promise((resolve, reject) => {
        web3.eth
            .getAccounts()
            .then((address) => {
                let contract = new web3.eth.Contract(paymentABI, offer.paymentAddress);
                let request = contract.methods.payETH(
                    offer.payload.paymentID,
                    offer.payload.expiration,
                    offer.payload.tokenCost,
                    offer.payload.depositAddress,
                    offer.signature
                );
                return request.estimateGas({ value: offer.payload.ethCost, from: address[0] }).then((gas) => {
                    request
                        .send({
                            value: offer.payload.ethCost,
                            from: address[0],
                            gas: parseInt(gas * 1.25),
                            accessList: [{ address: offer.payload.depositAddress, storageKeys: [] }],
                        })
                        .on("transactionHash", (txhash) => {
                            resolve(txhash);
                        })
                        .on("error", (err) => {
                            reject(err);
                        });
                });
            })
            .catch((err) => {
                reject(err);
            });
    });
}
export function payERC20(offer, web3) {
    return new Promise((resolve, reject) => {
        web3.eth
            .getAccounts()
            .then((address) => {
                let paymentContract = new web3.eth.Contract(paymentABI, offer.paymentAddress);
                return paymentContract.methods
                    .target_token()
                    .call()
                    .then((token_address) => {
                        let erc20Contract = new web3.eth.Contract(approveABI, token_address);
                        return erc20Contract.methods
                            .balanceOf(address[0])
                            .call()
                            .then((balance) => {
                                let bnBalance = new web3.utils.BN(balance);
                                if (bnBalance.lt(offer.payload.tokenCost)) {
                                    throw "Insufficient token balance";
                                }
                                return erc20Contract.methods
                                    .allowance(address[0], offer.paymentAddress)
                                    .call()
                                    .then((allowance) => {
                                        let bnAllowance = new web3.utils.BN(allowance);
                                        if (bnAllowance.gt(new web3.utils.BN(offer.payload.tokenCost))) {
                                            let pay_call = paymentContract.methods.payERC20(
                                                offer.payload.paymentID,
                                                offer.payload.expiration,
                                                offer.payload.tokenCost,
                                                offer.payload.ethCost,
                                                offer.payload.depositAddress,
                                                offer.signature
                                            );
                                            return pay_call.estimateGas({ from: address[0] }).then((estimate) => {
                                                pay_call
                                                    .send({ from: address[0], gas: parseInt(estimate * 1.1) })
                                                    .on("transactionHash", (txhash) => {
                                                        resolve(txhash);
                                                    })
                                                    .on("error", (err) => {
                                                        console.log(err);
                                                        reject(err);
                                                    });
                                            });
                                        } else {
                                            if (web3.currentProvider.sendTransactionBatch) {
                                                let approve_call = erc20Contract.methods.approve(offer.paymentAddress, offer.payload.tokenCost);
                                                let pay_call = paymentContract.methods.payERC20(
                                                    offer.payload.paymentID,
                                                    offer.payload.expiration,
                                                    offer.payload.tokenCost,
                                                    offer.payload.ethCost,
                                                    offer.payload.depositAddress,
                                                    offer.signature
                                                );
                                                return web3.currentProvider
                                                    .estimateGasBatch([
                                                        { to: token_address, value: 0, data: approve_call.encodeABI() },
                                                        { to: offer.paymentAddress, value: 0, data: pay_call.encodeABI() },
                                                    ])
                                                    .then((estimates) => {
                                                        return web3.currentProvider
                                                            .sendTransactionBatch([
                                                                {
                                                                    to: token_address,
                                                                    value: "0",
                                                                    gasLimit: parseInt(estimates[0] * 1.1).toString(),
                                                                    data: approve_call.encodeABI(),
                                                                },
                                                                {
                                                                    to: offer.paymentAddress,
                                                                    value: "0",
                                                                    gasLimit: parseInt(estimates[1] * 1.1).toString(),
                                                                    data: pay_call.encodeABI(),
                                                                },
                                                            ])
                                                            .then((txhash) => {
                                                                resolve(txhash.transactionHash);
                                                            });
                                                    });
                                            } else {
                                                return signPermit(web3.currentProvider, networkId.value, token_address, {
                                                    holder: address[0],
                                                    spender: offer.paymentAddress,
                                                    expiry: offer.payload.expiration,
                                                    allowed: 1,
                                                })
                                                    .then(({ sig }) => {
                                                        console.log(sig);
                                                        let v = sig.slice(sig.length - 2);
                                                        sig = `0x${v}${sig.slice(2, sig.length - 2)}`;

                                                        let request = paymentContract.methods.payERC20Permit(
                                                            offer.payload.paymentID,
                                                            offer.payload.expiration,
                                                            offer.payload.tokenCost,
                                                            offer.payload.ethCost,
                                                            offer.payload.depositAddress,
                                                            offer.signature,
                                                            sig
                                                        );
                                                        return request.estimateGas({ from: address[0] }).then((estimate) => {
                                                            request
                                                                .send({ from: address[0], gas: parseInt(estimate * 1.1) })
                                                                .on("transactionHash", (txhash) => {
                                                                    resolve(txhash);
                                                                })
                                                                .on("error", (err) => {
                                                                    console.log(err);
                                                                    reject(err);
                                                                });
                                                        });
                                                    })
                                                    .catch(() => {
                                                        let request = erc20Contract.methods.approve(offer.paymentAddress, UNLIMITED_ALLOWANCE);
                                                        return request.estimateGas({ from: address[0] }).then((estimate) => {
                                                            request
                                                                .send({ from: address[0], gas: parseInt(estimate * 1.1) })
                                                                .on("transactionHash", () => {
                                                                    let pay_call = paymentContract.methods.payERC20(
                                                                        offer.payload.paymentID,
                                                                        offer.payload.expiration,
                                                                        offer.payload.tokenCost,
                                                                        offer.payload.ethCost,
                                                                        offer.payload.depositAddress,
                                                                        offer.signature
                                                                    );
                                                                    return pay_call.estimateGas({ from: address[0] }).then((estimate) => {
                                                                        pay_call
                                                                            .send({ from: address[0], gas: parseInt(estimate * 1.1) })
                                                                            .on("transactionHash", (txhash) => {
                                                                                resolve(txhash);
                                                                            })
                                                                            .on("error", (err) => {
                                                                                reject(err);
                                                                            });
                                                                    });
                                                                });
                                                        });
                                                    });
                                            }
                                        }
                                    });
                            });
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function signPermit(provider, networkId, verifyingContract, message) {
    let tokenAbi = [
        {
            constant: true,
            inputs: [{ internalType: "address", name: "", type: "address" }],
            name: "nonces",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "name",
            outputs: [{ internalType: "string", name: "", type: "string" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "version",
            outputs: [{ internalType: "string", name: "", type: "string" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { internalType: "address", name: "holder", type: "address" },
                { internalType: "address", name: "spender", type: "address" },
                { internalType: "uint256", name: "nonce", type: "uint256" },
                { internalType: "uint256", name: "expiry", type: "uint256" },
                { internalType: "bool", name: "allowed", type: "bool" },
                { internalType: "uint8", name: "v", type: "uint8" },
                { internalType: "bytes32", name: "r", type: "bytes32" },
                { internalType: "bytes32", name: "s", type: "bytes32" },
            ],
            name: "permit",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
    ];

    let tokenContract = new new Web3(provider).eth.Contract(tokenAbi, verifyingContract);
    let myAddr = message.holder;
    console.log(tokenContract);
    let [nonce, name, version] = await Promise.all([
        tokenContract.methods.nonces(myAddr).call(),
        tokenContract.methods.name().call(),
        tokenContract.methods.version().call(),
    ]);
    console.log(nonce, name, version, verifyingContract);
    if (message.nonce === undefined) {
        message = { ...message, nonce: nonce.toString() };
    }

    let domain = {
        name: name,
        version: version,
        chainId: parseInt(networkId.toString()),
        verifyingContract: verifyingContract,
    };
    console.log(domain);

    let typedData = {
        types: {
            EIP712Domain: domainSchema,
            Permit: permitSchema,
        },
        primaryType: "Permit",
        domain,
        message,
    };

    let sig = (await provider.send("eth_signTypedData_v3", [myAddr, JSON.stringify(typedData)]))["result"];

    return { domain, message, sig };
}
