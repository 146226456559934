<script setup>
import selectToken from "@/components/dashboard/selectToken.vue";
import { ref } from "@vue/reactivity";
import getCookie from "@/scripts/utils/getCookie.js";
import { payWithETH, payWithERC20 } from "../../scripts/dashboard_functs/token_purchase/payInTokens";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
const requests = ref(null);
const tokenVal = ref(null);
const store = useStore();
const form = ref(null);
const route = useRoute();

const submitTokenNo = () => {
    fetch(`/api/org/${route.query.org}/credits/quote`, {
        method: "POST",
        body: JSON.stringify({
            requests: parseInt(requests.value),
        }),
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
    })
        .then((res) => {
            return res.json();
        })
        .then((r) => {
            if (r.ok) {
                store.state.offer = r;
                switch (store.state.token_for_purchase) {
                    case "DAI":
                        tokenVal.value = `${store.state.offer.payload.tokenCost / 10 ** 18}  DAI`;
                        break;
                    case "ETH":
                        tokenVal.value = `${store.state.offer.payload.ethCost / 10 ** 18}  ETH`;
                        break;
                    default:
                        break;
                }
            }
        });
};

const paymethod = () => {
    switch (store.state.token_for_purchase) {
        case "ETH":
            payWithETH(form.value);
            break;
        case "DAI":
            payWithERC20(form.value);
            break;
        default:
            break;
    }
};
</script>

<template>
    <div class="wrap dashboard_root flex_col">
        <header class="flex_col">
            <h2 class="ta_center">Buy Request Credits</h2>
            <p class="ta_center">Select Token to purchase requests in & mode of purchase; by token amount or no of requests</p>
        </header>

        <form ref="form" class="flex_col" @submit.prevent="paymethod">
            <selectToken @submitOnSelect="submitTokenNo" />
            <div class="input_req flex_col">
                <label for="requests"> Enter No of Requests </label>
                <input required @input="submitTokenNo" v-model="requests" name="requests" placeholder="100,000" type="number" />
            </div>
            <div>
                <input v-if="$store.state.token_for_purchase === 'DAI'" type="text" v-model="tokenVal" />

                <input v-if="$store.state.token_for_purchase === 'ETH'" type="text" v-model="tokenVal" />
                <span class="copy">{{ $store.state.token_for_purchase }}</span>
            </div>
            <div class="btn_long btn_color">
                <button type="submit">Pay in {{ $store.state.token_for_purchase }}</button>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
h2 {
    color: $offblack;
    padding: 0px;
}
header {
    gap: 1.5rem;
    align-items: center;
    p {
        width: 60%;
        @include media("<=tablet") {
            width: 70%;
        }
        @include media("<=phone-tab") {
            width: 100%;
        }
    }
}
form {
    padding: 2rem;
    background: light !important;
    width: 35rem;
    gap: 1.5rem;
    border-radius: 1rem;
    margin: 0 auto;
    box-shadow: 1px 1px 14px 2px #e4e4e4;
    @include media("<=tablet") {
        width: 30rem;
    }
    @include media("<=phone-tab") {
        width: 95%;
    }
    @include media("<=phone") {
        width: 100%;
        padding: 1rem;
    }
    .input_req {
        gap: 0.5rem;
    }
    .select_t_q {
        select {
            background: $fadegrey;
            border: none;
            padding: 0.2rem 0;
        }
    }
    input {
        padding: 0.9rem 1rem;
        width: 100%;
        border-radius: 0.4rem;
        border: 1px solid grey;
    }
}
</style>
